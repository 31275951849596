import React from 'react';
import { Link } from 'react-router-dom';

const RoopkundRontiSaddleTrekDetails = () => {
  return (
    <>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Roopkund-Ronti Saddle Trek</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Destinations</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Roopkund-Ronti Saddle Trek</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-primary text-uppercase mb-2">Roopkund-Ronti Saddle Trek</h6>
              <h1 className="display-6 mb-4">The Ultimate High-Altitude Adventure</h1>
              <p className="mb-5">The Roopkund-Ronti Saddle Trek extends the famous Roopkund Trek, offering an even more challenging adventure with high-altitude passes and breathtaking views. This trek is ideal for experienced trekkers seeking a true Himalayan challenge.</p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <span>Chamoli District, Uttarakhand, India</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Altitude</h5>
                  </div>
                  <span>Ronti Saddle: Approximately 17,500 feet (5,334 meters)</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Difficulty</h5>
                  </div>
                  <span>Challenging</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Best Time to Visit</h5>
                  </div>
                  <span>May to June and September to October</span>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
              <p className="mb-5">Key details about the Roopkund-Ronti Saddle Trek:</p>
              <ul className="list-unstyled">
                <li><strong>Base Camp:</strong> Lohajung</li>
                <li><strong>Duration:</strong> 9 to 11 days</li>
                <li><strong>Route:</strong> Lohajung → Didna Village → Ali Bugyal → Bedni Bugyal → Patar Nachauni → Bhagwabasa → Roopkund Lake → Ronti Saddle → Homkund → Sutol Village → Lohajung</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
              <p className="mb-5">Highlights of the trek include:</p>
              <ul className="list-unstyled">
                <li><strong>Roopkund Lake:</strong> Mysterious lake with ancient human skeletons</li>
                <li><strong>Ronti Saddle:</strong> High-altitude pass with panoramic views of Trishul, Nanda Ghunti, and Nanda Devi Sanctuary</li>
                <li><strong>Panoramic Views:</strong> Stunning views of Himalayan peaks and expansive landscapes</li>
                <li><strong>Diverse Landscapes:</strong> Forests, meadows, rocky terrains, and snow-covered passes</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
              <p className="mb-5">To prepare for this demanding trek:</p>
              <ul className="list-unstyled">
                <li><strong>Physical Fitness:</strong> Requires excellent fitness, endurance, and prior high-altitude trekking experience</li>
                <li><strong>Altitude Acclimatization:</strong> Essential to prevent Acute Mountain Sickness (AMS)</li>
                <li><strong>Guides and Porters:</strong> Experienced guides and porters are crucial for navigation and safety</li>
                <li><strong>Permits:</strong> Required and can be arranged in Lohajung or through trekking agencies</li>
                <li><strong>Packing Essentials:</strong> Warm clothing, high-quality trekking boots, rain gear, crampons, gaiters, and a comprehensive first-aid kit</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
              <p className="mb-5">To reach the starting point:</p>
              <ul className="list-unstyled">
                <li><strong>By Air:</strong> Nearest airport is Pantnagar, with a drive to Lohajung</li>
                <li><strong>By Rail:</strong> Nearest railway station is Kathgodam</li>
                <li><strong>By Road:</strong> Accessible from Kathgodam or Rishikesh to Lohajung</li>
              </ul>
              <div className="row mt-3 gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <Link to="/Book-Now"><button className="btn btn-primary py-sm-3 px-sm-5">Book Now</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Roopkund-Ronti Saddle Trek" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="High Altitude View" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoopkundRontiSaddleTrekDetails;
