// src/ImageGallery.js
import React, { useState } from 'react';

const images = [
  { src: '/img/Gallery/gallery (1).jpg', alt: 'Image 1' },
  { src: '/img/Gallery/gallery (2).jpg', alt: 'Image 2' },
  { src: '/img/Gallery/gallery (3).jpg', alt: 'Image 3' },
  { src: '/img/Gallery/gallery (4).jpg', alt: 'Image 4' },
  { src: '/img/Gallery/gallery (5).jpg', alt: 'Image 5' },
  { src: '/img/Gallery/gallery (6).jpg', alt: 'Image 6' },
  { src: '/img/Gallery/gallery (7).jpg', alt: 'Image 7' },
  { src: '/img/Gallery/gallery (8).jpg', alt: 'Image 8' },
  { src: '/img/Gallery/gallery (9).jpg', alt: 'Image 9' },
  { src: '/img/Gallery/gallery (10).jpg', alt: 'Image 10' },
  { src: '/img/Gallery/gallery (11).jpg', alt: 'Image 11' },
  { src: '/img/Gallery/gallery (12).jpg', alt: 'Image 12' },
  { src: '/img/Gallery/gallery (13).jpg', alt: 'Image 13' },
  { src: '/img/Gallery/gallery (14).jpg', alt: 'Image 14' },
  { src: '/img/Gallery/gallery (15).jpg', alt: 'Image 15' },
  { src: '/img/Gallery/gallery (16).jpg', alt: 'Image 16' },
  { src: '/img/Gallery/gallery (17).jpg', alt: 'Image 17' },
  { src: '/img/Gallery/gallery (18).jpg', alt: 'Image 18' },
  { src: '/img/Gallery/gallery (19).jpg', alt: 'Image 19' },
  { src: '/img/Gallery/gallery (20).jpg', alt: 'Image 20' },
  { src: '/img/Gallery/gallery (21).jpg', alt: 'Image 21' },
  { src: '/img/Gallery/gallery (22).jpg', alt: 'Image 22' },
  { src: '/img/Gallery/gallery (23).jpg', alt: 'Image 23' },
  { src: '/img/Gallery/gallery (24).jpg', alt: 'Image 24' },
  { src: '/img/Gallery/gallery (25).jpg', alt: 'Image 25' },
  { src: '/img/Gallery/gallery (26).jpg', alt: 'Image 26' },
 
];

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className='container'>
      <style>{`
        .gallery-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          justify-content: center;
        }

        .gallery-item {
          flex: 1 0 21%; /* Adjusts the size of each image */
          max-width: 200px;
          cursor: pointer;
        }

        .gallery-image {
          width: 100%;
          height: auto;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          position: relative;
          max-width: 70%;
          max-height: 80%;
        }

        .modal-image {
          width: 100%;
          height:500px;
          border-radius: 8px;
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background: none;
          border: none;
          font-size: 24px;
          color: black;
          cursor: pointer;
        }
      `}</style>
      
      <div className="gallery-container mb-5">
        {images.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => handleImageClick(image)}>
            <img src={image.src} alt={image.alt} className="gallery-image" />
          </div>
        ))}
      </div>
      
      {selectedImage && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <img src={selectedImage.src} alt={selectedImage.alt} className="modal-image" />
            <button className="close-button" onClick={handleCloseModal}>✖</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
