import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
    <div class="container-fluid bg-dark text-light p-0">
         <div class="row gx-0 d-none d-lg-flex">
             <div class="col-lg-7 px-5 text-start">
                 <div class="h-100 d-inline-flex align-items-center me-4">
                     <small class="fa fa-map-marker-alt text-primary me-2"></small>
                     <small>Wachham 
                     , Bageshwar, Uttarakhand</small>
                 </div>
                 <div class="h-100 d-inline-flex px-5 align-items-center">
                     <small class="far fa-clock text-primary me-2"></small>
                     <small>Mon - Fri : 09.00 AM - 09.00 PM</small>
                 </div>
                 <div class="h-100 d-inline-flex align-items-center">
                     <small class="far fa-envelope text-primary me-2"></small>
                     <small>chandandanu11@gmail.com </small>
                 </div>
             </div>
             <div class="col-lg-5 px-5 text-end">
                 <div class="h-100 d-inline-flex align-items-center me-4">
                     <small class="fa fa-phone-alt text-primary me-2"></small>
                     <small>7579060903</small>
                 </div>
                 <div class="h-100 d-inline-flex align-items-center mx-n2">
                     <a class="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" href=""><i class="fab fa-facebook-f"></i></a>
                     <a class="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" href=""><i class="fab fa-twitter"></i></a>
                     <a class="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" href=""><i class="fab fa-linkedin-in"></i></a>
                     <a class="btn btn-square btn-link rounded-0" href=""><i class="fab fa-instagram"></i></a>
                 </div>
             </div>
         </div>
     </div>
     {/* <!-- Topbar End --> */}
 
 
     {/* <!-- Navbar Start --> */}
     <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
         <Link to="/" class="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
             <img src="/img/logo.png" alt="" height='70px'/>
         </Link>
         <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
             <span class="navbar-toggler-icon"></span>
         </button>
         <div class="collapse navbar-collapse" id="navbarCollapse">
             <div class="navbar-nav ms-auto p-4 p-lg-0">
                 <Link to="/" class="nav-item nav-link active">Home</Link>
                 <Link to="/about" class="nav-item nav-link">About</Link>
                 <Link to="/destination" class="nav-item nav-link">Trek Destination</Link>
                 {/* <div class="nav-item dropdown">
                     <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                     <div class="dropdown-menu bg-light m-0">
                         <Link to="feature.html" class="dropdown-item">Features</Link>
                         <Link to="appointment.html" class="dropdown-item">Appointment</Link>
                         <Link to="team.html" class="dropdown-item">Our Team</Link>
                         <Link to="testimonial.html" class="dropdown-item">Testimonial</Link>
                         <Link to="404.html" class="dropdown-item">404 Page</Link>
                     </div>
                 </div> */}
                 <Link to="/treks" class="nav-item nav-link">Trek Times</Link>
                 <Link to="/contact" class="nav-item nav-link">Contact</Link>
             </div>
             <Link to="/Book-Now" class="btn btn-primary py-4 px-lg-5 d-none d-lg-block">BOOK NOW <i class="fa fa-arrow-right ms-3"></i></Link>
         </div>
     </nav>
     {/* <!-- Navbar End --> */}
 
    </>
  )
}

export default Header