import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            {/* <!-- Footer Start --> */}
            <div class="container-fluid bg-dark text-light footer my-6 mb-0 py-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-3 col-md-6">
                            <img src="/img/footer_logo.png" alt="" height='140px' />
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <h4 class="text-white mb-4">Get In Touch</h4>
                            <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i> <p>Village - Wachham <br />
                                Post office - Khati <br />
                                Sadistic - kapkot<br />
                                Distic - Bageshwar<br />
                                Pin code - 263679<br />
                                Uttarakhand</p></p>
                            <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>7579060903</p>
                            <p class="mb-2"><i class="fa fa-envelope me-3"></i> chandandanu11@gmail.com</p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <h4 class="text-light mb-4">Popular Links</h4>
                            <Link class="btn btn-link" to="/about">About Us</Link>
                            <Link class="btn btn-link" to="/contact">Contact Us</Link>
                            <Link class="btn btn-link" to="/destination">Trek Destination</Link>
                            <Link class="btn btn-link" to="/treks">Trek Times</Link>
                            <Link class="btn btn-link" to="/Book-Now">Book now</Link>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <h4 class="text-light mb-4">Newsletter</h4>
                            <form action="">
                                <div class="input-group">
                                    <input type="text" class="form-control p-3 border-0" placeholder="Your Email Address" />
                                    <button class="btn btn-primary">Sign Up</button>
                                </div>
                            </form>
                            <h6 class="text-white mt-4 mb-3">Follow Us</h6>
                            <div class="d-flex pt-2">
                                <a class="btn btn-square btn-outline-light me-1" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-square btn-outline-light me-1" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-outline-light me-1" href=""><i class="fab fa-youtube"></i></a>
                                <a class="btn btn-square btn-outline-light me-0" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}


            {/* <!-- Copyright Start --> */}
            <div class="container-fluid copyright text-light py-4 wow fadeIn" data-wow-delay="0.1s">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <Link to="/">EXPLOREMOZO</Link>, All Right Reserved.
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Copyright End --> */}
        </>
    )
}
export default Footer