import React from 'react';
import { Link } from 'react-router-dom';

const RoopkundTrekDetails = () => {
  return (
    <>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Roopkund Trek</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Destinations</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Roopkund Trek</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-primary text-uppercase mb-2">Roopkund Trek</h6>
              <h1 className="display-6 mb-4">The Mystery Lake Trek</h1>
              <p className="mb-5">The Roopkund Trek, also known as the "Mystery Lake Trek," is an exhilarating adventure in the Chamoli district of Uttarakhand, India. Famous for the skeletal remains found at the lake's bottom, this trek combines natural beauty, mystery, and cultural experiences.</p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <span>Chamoli District, Uttarakhand, India</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Altitude</h5>
                  </div>
                  <span>Approximately 16,499 feet (5,029 meters)</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Difficulty</h5>
                  </div>
                  <span>Moderate to Challenging</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Best Time to Visit</h5>
                  </div>
                  <span>May to June and September to October</span>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
              <p className="mb-5">Key details about the Roopkund Trek:</p>
              <ul className="list-unstyled">
                <li><strong>Base Camp:</strong> Lohajung</li>
                <li><strong>Duration:</strong> 8 to 9 days</li>
                <li><strong>Route:</strong> Lohajung → Didna Village → Ali Bugyal → Bedni Bugyal → Patar Nachauni → Bhagwabasa → Roopkund Lake → Return</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Itnery</h6>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Day 1
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Pick-up Location (Rishikesh/Kathgodam/Haridwar/Dehradun) to Lohanjang</strong>: 250km drive, approximately 9-10 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Day 2
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Lohanjang to Didina Village</strong>: 10km trek, approximately 5 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Day 3
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Didina Village to Ali Bugyal</strong>: 12km trek, approximately 7-8 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Day 4
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Ali Bugyal to Pattharnachauni</strong>: 10km trek, approximately 6 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Day 5
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Pattharnachauni to Baghubasa</strong>: 10km trek, approximately 5 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Day 6
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Baghubasa to Roopkund (Visit) and Back to Pattharnachauni</strong>: 20km trek, approximately 8 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      Day 7
                    </button>
                  </h2>
                  <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Pattharnachauni to Vaana Village</strong>: 13km trek, and then 11km drive to Lohanjang.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      Day 8
                    </button>
                  </h2>
                  <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Lohanjang to Your Destination</strong>: Drive back.
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
              <p className="mb-5">Highlights of the trek include:</p>
              <ul className="list-unstyled">
                <li><strong>Roopkund Lake:</strong> Mysterious lake with skeletal remains and stunning surroundings</li>
                <li><strong>Panoramic Himalayan Views:</strong> Views of Trishul and Nanda Ghunti</li>
                <li><strong>Diverse Landscapes:</strong> Forests, meadows, and snowy regions</li>
                <li><strong>Cultural Experience:</strong> Interaction with local Garhwali villages</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
              <p className="mb-5">To prepare for the trek:</p>
              <ul className="list-unstyled">
                <li><strong>Physical Fitness:</strong> Required due to challenging terrain and high altitude</li>
                <li><strong>Altitude Acclimatization:</strong> Essential to avoid altitude sickness</li>
                <li><strong>Guides and Porters:</strong> Highly recommended for navigating the terrain</li>
                <li><strong>Permits:</strong> Required and can be arranged in Lohajung or through trekking agencies</li>
                <li><strong>Packing Essentials:</strong> Warm clothing, trekking boots, rain gear, and a first-aid kit</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
              <p className="mb-5">To reach the starting point:</p>
              <ul className="list-unstyled">
                <li><strong>By Air:</strong> Nearest airport is Pantnagar, with a drive to Lohajung</li>
                <li><strong>By Rail:</strong> Nearest railway station is Kathgodam</li>
                <li><strong>By Road:</strong> Accessible from Kathgodam or Rishikesh to Lohajung</li>
              </ul>
              <div className="row mt-3 gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <Link to="/Book-Now"><button className="btn btn-primary py-sm-3 px-sm-5">Book Now</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="Roopkund Trek" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Mountain View" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoopkundTrekDetails;
