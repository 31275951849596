import React from 'react';
import { Link } from 'react-router-dom';

const RanthanTopTrekDetails = () => {
  return (
    <>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Ranthan Top Trek</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Destinations</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Ranthan Top Trek</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-primary text-uppercase mb-2">Ranthan Top Trek</h6>
              <h1 className="display-6 mb-4">Ranthan Top Trek</h1>
              <p className="mb-5">Ranthan Top is a lesser-known trekking destination in the Kumaon region of Uttarakhand, India, offering stunning Himalayan views and a chance to explore the natural beauty and cultural richness of the area.</p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <span>Kumaon, Uttarakhand, India</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Altitude</h5>
                  </div>
                  <span>Approximately 10,000 feet (3,048 meters)</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Difficulty</h5>
                  </div>
                  <span>Easy to Moderate</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Best Time to Visit</h5>
                  </div>
                  <span>April to June and September to November</span>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
              <p className="mb-5">Key details about the Ranthan Top Trek:</p>
              <ul className="list-unstyled">
                <li><strong>Base Camp:</strong> Near villages like Gogina or Munsiyari</li>
                <li><strong>Duration:</strong> 3 to 4 days</li>
                <li><strong>Route:</strong> Starting Point → Base Camp → Ranthan Top → Exploration → Descent</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
              <p className="mb-5">Highlights of the trek include:</p>
              <ul className="list-unstyled">
                <li><strong>Panoramic Himalayan Views:</strong> Views of peaks like Nanda Devi, Nanda Kot, and Panchachuli</li>
                <li><strong>Untouched Natural Beauty:</strong> Serene landscapes, forests, and meadows</li>
                <li><strong>Cultural Experience:</strong> Interaction with local Kumaoni villages</li>
                <li><strong>Wildlife and Flora:</strong> Opportunities to spot various birds, animals, and diverse flora</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
              <p className="mb-5">To prepare for the trek:</p>
              <ul className="list-unstyled">
                <li><strong>Physical Fitness:</strong> Suitable for beginners with basic fitness</li>
                <li><strong>Guides and Porters:</strong> Hiring a local guide can enhance the experience</li>
                <li><strong>Permits:</strong> No special permits are typically required, but check local regulations</li>
                <li><strong>Packing Essentials:</strong> Light woolens, trekking shoes, rain gear, and a first-aid kit</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
              <p className="mb-5">To reach the starting point:</p>
              <ul className="list-unstyled">
                <li><strong>By Air:</strong> Nearest airport is Pantnagar, with a drive to Munsiyari or Bageshwar</li>
                <li><strong>By Rail:</strong> Nearest railway station is Kathgodam, about 200 km from Munsiyari</li>
                <li><strong>By Road:</strong> Accessible by road from Munsiyari or Bageshwar</li>
              </ul>
              <div className="row mt-3 gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <Link to="/Book-Now"><button className="btn btn-primary py-sm-3 px-sm-5">Book Now</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Ranthan Top Trek" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Mountain View" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RanthanTopTrekDetails;
