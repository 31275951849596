import React from 'react'
import { Link } from 'react-router-dom'
const KanakataPassDetails = () => {
  return (
    <>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Kanakata Pass Trek</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Destinations</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Kanakata Pass Trek</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-primary text-uppercase mb-2">Kanakata Pass Trek</h6>
              <h1 className="display-6 mb-4">Kanakata Pass Trek</h1>
              <p className="mb-5">The Kanakata Pass Trek is a challenging and remote high-altitude trek in the Garhwal region of Uttarakhand, India. This trek offers thrilling adventures for seasoned trekkers and mountaineers, featuring stunning panoramic views and a diverse range of terrains.</p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <span>Garhwal, Uttarakhand, India</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Difficulty</h5>
                  </div>
                  <span>Challenging</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Best Time to Visit</h5>
                  </div>
                  <span>May to June and September to October</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Duration</h5>
                  </div>
                  <span>8-10 days</span>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
              <p className="mb-5">The Kanakata Pass Trek provides an adventurous experience through diverse terrains. Here are the key details:</p>
              <ul className="list-unstyled">
                <li><strong>Altitude:</strong> Approximately 15,000 feet (4,572 meters)</li>
                <li><strong>Trek Distance:</strong> Approximately 80 km (round trip)</li>
                <li><strong>Route:</strong> Ghuttu to Reeh, Reeh to Gangi, Gangi to Kharsoli, Kharsoli to Kalyani, Kalyani to Kanakata Pass, and return to Ghuttu</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Itnery</h6>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Day 1
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Kathgodam to Khati</strong>: 8-10 hours drive.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Day 2
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Khati to Jatoli</strong>: 7km trek, approximately 4 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Day 3
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Jatoli to Kathaliya</strong>: 12km trek, approximately 7-9 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Day 4
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Kathaliya to Beluni Top</strong>: 5km trek, approximately 4 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Day 5
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Beluni Top via Devi Kund to Bhanaar</strong>: 10km trek, approximately 7-8 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Day 6
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Bhanaar via Naagkund to Kanakata Pass and Patharkhuni</strong>: 14km trek, approximately 8-10 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      Day 7
                    </button>
                  </h2>
                  <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Patharkhuni to Jatoli</strong>: 12km trek, approximately 7-8 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      Day 8
                    </button>
                  </h2>
                  <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Jatoli to Khati</strong>: 7km trek, approximately 3 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      Day 9
                    </button>
                  </h2>
                  <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Khati to Kathgodam</strong>: Drive back.
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
              <p className="mb-5">The trek features several highlights:</p>
              <ul className="list-unstyled">
                <li><strong>Panoramic Views:</strong> Views of major Himalayan peaks like Bandarpunch, Kedarnath, Chaukhamba, and Trishul</li>
                <li><strong>Varied Terrain:</strong> Dense forests, lush meadows, rocky paths, and snow-covered sections</li>
                <li><strong>Remote Experience:</strong> Solitude and unspoiled natural beauty</li>
                <li><strong>Cultural Experience:</strong> Interactions with Garhwali communities in remote villages</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
              <p className="mb-5">To ensure a successful trek:</p>
              <ul className="list-unstyled">
                <li><strong>Physical Fitness:</strong> Excellent physical fitness is crucial due to challenging terrain and high altitude</li>
                <li><strong>Guides and Porters:</strong> Hiring a local guide and porters is advisable due to the remote and less-marked route</li>
                <li><strong>Permits:</strong> Obtain necessary trekking permits from Uttarkashi</li>
                <li><strong>Packing Essentials:</strong> Warm clothing, sturdy trekking boots, rain gear, and a first-aid kit are essential. Prepare for altitude sickness with appropriate medication</li>
                <li><strong>Acclimatization:</strong> Proper acclimatization is crucial to avoid altitude sickness</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
              <p className="mb-5">You can reach the starting point of the trek as follows:</p>
              <ul className="list-unstyled">
                <li><strong>By Air:</strong> Nearest airport is Jolly Grant Airport, with a drive to Uttarkashi</li>
                <li><strong>By Rail:</strong> Nearest railway station is Dehradun, about 150 km from Uttarkashi</li>
                <li><strong>By Road:</strong> Uttarkashi is well-connected by road to major towns like Dehradun and Rishikesh</li>
              </ul>
              <div className="row mt-3 gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <Link to="/Book-Now"><button className="btn btn-primary py-sm-3 px-sm-5">Book Now</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Kanakata Pass Trek" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="Mountain View" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KanakataPassDetails