import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TestimonialCarousel = () => {
    const options = {
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        items: 1,
    };

    return (
        <div className="container-xxl py-6">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <h6 className="text-primary text-uppercase mb-2">Testimonial</h6>
                    <h1 className="display-6 mb-4">What Our Clients Say!</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                        <OwlCarousel className="owl-carousel testimonial-carousel" {...options}>
                            <div className="testimonial-item text-center">
                                <div className="position-relative mb-5">
                                    <img className="img-fluid rounded-circle mx-auto" src="img/testimonial-1.jpg" style={{height:'70px',width:'100px'}} alt="" />
                                    <div className="position-absolute top-100 start-50 translate-middle d-flex align-items-center justify-content-center bg-white rounded-circle" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-quote-left fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <p className="fs-4">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                <hr className="w-25 mx-auto" />
                                <h5>Client Name</h5>
                                <span>Profession</span>
                            </div>
                            <div className="testimonial-item text-center">
                                <div className="position-relative mb-5">
                                    <img className="img-fluid rounded-circle mx-auto" src="img/testimonial-2.jpg" style={{height:'70px',width:'100px'}} alt="" />
                                    <div className="position-absolute top-100 start-50 translate-middle d-flex align-items-center justify-content-center bg-white rounded-circle" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-quote-left fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <p className="fs-4">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                <hr className="w-25 mx-auto" />
                                <h5>Client Name</h5>
                                <span>Profession</span>
                            </div>
                            <div className="testimonial-item text-center">
                                <div className="position-relative mb-5">
                                    <img className="img-fluid rounded-circle mx-auto" src="img/testimonial-3.jpg" style={{height:'100px',width:'100px'}} alt="" />
                                    <div className="position-absolute top-100 start-50 translate-middle d-flex align-items-center justify-content-center bg-white rounded-circle" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-quote-left fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <p className="fs-4">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                <hr className="w-25 mx-auto" />
                                <h5>Client Name</h5>
                                <span>Profession</span>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCarousel;
