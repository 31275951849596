import React from 'react';
import { Link } from 'react-router-dom';

const BrahmatalTrekDetails = () => {
  return (
    <>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Brahmatal Trek</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Destinations</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Brahmatal Trek</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-primary text-uppercase mb-2">Brahmatal Trek</h6>
              <h1 className="display-6 mb-4">A Serene Journey Through the Himalayan Landscapes</h1>
              <p className="mb-5">The Brahmatal Trek is a picturesque adventure set in the Indian Himalayas, offering stunning views, serene high-altitude lakes, and beautiful alpine meadows. This trek is known for its diverse terrain, including snowy and non-snowy paths, making it suitable for trekkers of various skill levels.</p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <span>Chamoli District, Uttarakhand, India</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Altitude</h5>
                  </div>
                  <span>Approximately 12,250 feet (3,735 meters)</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Difficulty</h5>
                  </div>
                  <span>Moderate</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Best Time to Visit</h5>
                  </div>
                  <span>January to March (for snow) and April to June (for meadows and clear views)</span>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
              <p className="mb-5">Details about the Brahmatal Trek:</p>
              <ul className="list-unstyled">
                <li><strong>Base Camp:</strong> Lohajung</li>
                <li><strong>Duration:</strong> 6 to 8 days</li>
                <li><strong>Route:</strong> Lohajung → Bekaltal → Brahmatal → Khorte → Lohajung</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Itnery</h6>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Day 1
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Rishikesh to Lohanjang</strong>: 250km drive, approximately 9 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Day 2
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Lohanjang to Tilandi</strong>: 8km trek, approximately 7 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Day 3
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Tilandi to Jhandi Top via Brahmatal</strong>: 5km trek, approximately 4 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Day 4
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Brahmatal to Brahmatal Summit and Back to Brahmatal</strong>: 8km trek, approximately 6 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Day 5
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Brahmatal to Lohanjang</strong>: 10km trek, approximately 7-8 hours.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Day 6
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Lohanjang to Rishikesh</strong>: Drive back.
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
              <p className="mb-5">Highlights of the trek include:</p>
              <ul className="list-unstyled">
                <li><strong>Brahmatal Lake:</strong> A high-altitude lake with stunning reflections of the surrounding mountains</li>
                <li><strong>Panoramic Views:</strong> Views of Himalayan peaks like Trishul and Nanda Ghunti</li>
                <li><strong>Alpine Meadows:</strong> Beautiful meadows adorned with wildflowers during spring and summer</li>
                <li><strong>Forested Trails:</strong> Enchanting forests of oak, rhododendron, and pine</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
              <p className="mb-5">To prepare for this trek:</p>
              <ul className="list-unstyled">
                <li><strong>Physical Fitness:</strong> Moderate difficulty requires good physical fitness due to high altitude and varying terrain</li>
                <li><strong>Altitude Acclimatization:</strong> Essential to avoid Acute Mountain Sickness (AMS); acclimatize at various altitudes</li>
                <li><strong>Guides and Porters:</strong> Hiring experienced guides and porters is recommended for logistics and navigation</li>
                <li><strong>Permits:</strong> No special permits generally required; check with local authorities or agencies</li>
                <li><strong>Packing Essentials:</strong> Warm clothing, sturdy trekking boots, rain gear, and a well-equipped first-aid kit are crucial</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
              <p className="mb-5">To reach the starting point:</p>
              <ul className="list-unstyled">
                <li><strong>By Air:</strong> Nearest airport is Jolly Grant, Dehradun; drive to Lohajung</li>
                <li><strong>By Rail:</strong> Nearest railway station is Kathgodam or Rishikesh; drive to Lohajung</li>
                <li><strong>By Road:</strong> Accessible from Kathgodam or Rishikesh to Lohajung</li>
              </ul>
              <div className="row mt-3 gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <Link to="/Book-Now"><button className="btn btn-primary py-sm-3 px-sm-5">Book Now</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="Brahmatal Trek" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Brahmatal Lake View" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrahmatalTrekDetails;
