import React from 'react'
import { Link } from 'react-router-dom'

const RoopkundTrek = () => {
    return (
        <>
            <div class="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
                <div class="container text-center">
                    <h1 class="display-4 text-white animated slideInDown mb-4">Roopkund Trek</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb justify-content-center mb-0">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Destinations</a></li>
                            <li class="breadcrumb-item text-primary active" aria-current="page">Roopkund Trek</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="container-xxl py-6">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h6 class="text-primary text-uppercase mb-2">Roopkund Trek</h6>
                            <h1 class="display-6 mb-4">Roopkund Trek</h1>
                            <p class="mb-5">Experience the Jewel of Kumaon Discover the stunning Pindari Glacier, nestled in the heart of the Kumaon region. This moderate trek takes you through dense forests, charming villages, and awe-inspiring landscapes, culminating at the majestic Pindari Glacier, surrounded by towering peaks.</p>
                            <div class="row gy-5 gx-4">
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Location</h5>
                                    </div>
                                    <span>Kumaon region, Uttarakhand</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Difficulty</h5>
                                    </div>
                                    <span>Moderate</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Best Time to Visit</h5>
                                    </div>
                                    <span>April to June and September to November</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Duration</h5>
                                    </div>
                                    <span>8 to 9 days (depending on the itinerary and pace)</span>
                                </div>
                            </div>
                            <div class="row mt-3 gy-5 gx-4">
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                    <Link to='/Roopkund-Details'><button class="btn btn-primary py-sm-3 px-sm-5">More Details</button></Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                                <img class="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="" style={{ objectFit: 'cover' }} />
                                <img class="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="" style={{ width: '200px', height: '200px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoopkundTrek