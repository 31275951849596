import React from 'react'
import { Link } from 'react-router-dom'
import ImageGallery from './ImageGallery'
import TestimonialCarousel from './TestimonialCarousel'
import DestinationPage from './DestinationPage'
import TrekkingDestinations from './TrekkingDestinations'

const HomePage = () => {
    return (
        <>
            {/* <!-- Carousel Start --> */}
            <div class="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
                <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="w-100" src="img/banner/banner.png" alt="Image" />
                            <div class="carousel-caption">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-7">
                                            <h1 class="display-2 text-light mb-3 animated slideInDown">EXPLOREMOZO</h1>
                                            <h3 class="display-6  text-light mb-5 animated slideInDown">HIKE THE PEAKS</h3>
                                            <a href='#destinations' class="btn btn-primary py-sm-3 px-sm-5">Explore More</a>
                                            <a href='#about' class="btn btn-light py-sm-3 px-sm-5 ms-3">About Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="w-100" src="img/banner/banner2.png" alt="Image" />
                            <div class="carousel-caption">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-7">
                                            <h1 class="display-2 text-light mb-3 animated slideInDown">EXPLOREMOZO</h1>
                                            <h3 class="display-6  text-light mb-5 animated slideInDown">HIKE THE PEAKS</h3>
                                            <Link to='#destinations' class="btn btn-primary py-sm-3 px-sm-5">Explore More</Link>
                                            <Link to='#about' class="btn btn-light py-sm-3 px-sm-5 ms-3">About Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* <!-- Carousel End --> */}


            {/* <!-- Facts Start --> */}
            <div class="container-fluid facts py-5 pt-lg-0">
                <div class="container py-5 pt-lg-0">
                    <div class="row gx-0">
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                            <div class="bg-white shadow d-flex align-items-center h-100 p-4" style={{ minHeight: '150px' }}>
                                <div class="d-flex">
                                    <div class="flex-shrink-0 btn-lg-square bg-primary">
                                        <i class="fa fa-car text-white"></i>
                                    </div>
                                    <div class="ps-4">
                                        <h5>Scenic Trails</h5>
                                        <span>Explore breathtaking views and scenic trails tailored for every trekker.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div class="bg-white shadow d-flex align-items-center h-100 p-4" style={{ minHeight: '150px' }}>
                                <div class="d-flex">
                                    <div class="flex-shrink-0 btn-lg-square bg-primary">
                                        <i class="fa fa-users text-white"></i>
                                    </div>
                                    <div class="ps-4">
                                        <h5>Experienced Guides</h5>
                                        <span>Guided treks with experienced guides to ensure a safe and enriching experience.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div class="bg-white shadow d-flex align-items-center h-100 p-4" style={{ minHeight: '150px' }}>
                                <div class="d-flex">
                                    <div class="flex-shrink-0 btn-lg-square bg-primary">
                                        <i class="fa fa-map text-white"></i>
                                    </div>
                                    <div class="ps-4">
                                        <h5>Comprehensive Maps</h5>
                                        <span>Detailed maps and routes for every adventure to help you navigate with ease.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Facts End --> */}


            {/* <!-- About Start --> */}
            <div className="container-xxl py-6">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                                <img className="position-absolute w-100 h-100" src="img/Gallery/gallery (5).jpg" alt="" style={{ objectFit: 'cover' }} />
                                <img className="position-absolute top-0 start-0 bg-white pe-3 pb-3" src="img/Gallery/gallery (4).jpg" alt="" style={{ width: '200px', height: '200px' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h6 className="text-primary text-uppercase mb-2">About Us</h6>
                                <h1 className="display-6 mb-4">EXPLOREMOZO</h1>
                                <p>At EXPLOREMOZO, we believe that every trek is more than just a journey; it’s a chance to reconnect with nature, push your limits, and discover the hidden wonders of the world. Our team of expert guides and outdoor enthusiasts are passionate about creating unforgettable trekking experiences that cater to adventurers of all levels.</p>
                                <p className="mb-4">From the serene beauty of remote valleys to the challenging climbs of majestic peaks, we offer a diverse range of trekking routes that showcase the breathtaking landscapes and rich cultures of the regions we explore. Whether you're a seasoned trekker or a first-time hiker, we provide personalized itineraries and professional support to ensure your adventure is safe, enjoyable, and memorable.</p>
                                <div className="row g-2 mb-4 pb-2">
                                    <div className="col-sm-6">
                                        <i className="fa fa-check text-primary me-2"></i>Fully Licensed
                                    </div>
                                    <div className="col-sm-6">
                                        <i className="fa fa-check text-primary me-2"></i>Online Tracking
                                    </div>
                                    <div className="col-sm-6">
                                        <i className="fa fa-check text-primary me-2"></i>Affordable Pricing
                                    </div>
                                    <div className="col-sm-6">
                                        <i className="fa fa-check text-primary me-2"></i>Expert Guides
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col-sm-6">
                                        <Link className="btn btn-primary py-3 px-5" to="/destination">Explore More</Link>
                                    </div>
                                    <div className="col-sm-6">
                                        <a className="d-inline-flex align-items-center btn btn-outline-primary border-2 p-2" href="tel:7579060903">
                                            <span className="flex-shrink-0 btn-square bg-primary">
                                                <i className="fa fa-phone-alt text-white"></i>
                                            </span>
                                            <span className="px-3">+91 7579060903</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}

            <div class="container-xxl py-6">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h6 class="text-primary text-uppercase mb-2">Why Choose Us!</h6>
                            <h1 class="display-6 mb-4">Unforgettable Trekking Adventures</h1>
                            <p class="mb-5">Experience breathtaking landscapes, immersive nature, and thrilling challenges with our expertly guided trekking adventures. Whether you're a seasoned trekker or a first-time explorer, our treks offer a perfect blend of adventure and safety.</p>
                            <div class="row gy-5 gx-4">
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Expert Guides</h5>
                                    </div>
                                    <span>Our certified guides bring local expertise and years of trekking experience to ensure a safe and enriching journey.</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Stunning Routes</h5>
                                    </div>
                                    <span>Discover diverse trails, from lush forests and serene lakes to rugged mountains and awe-inspiring vistas.</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Comfortable Lodging</h5>
                                    </div>
                                    <span>Enjoy comfortable accommodations in scenic spots, with all essential amenities to relax and rejuvenate after a day’s trek.</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Customizable Packages</h5>
                                    </div>
                                    <span>Choose from a range of packages tailored to different skill levels and preferences, or create a custom adventure just for you.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                                <img class="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="Trekking Adventure" style={{ objectFit: 'cover' }} />
                                <img class="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Mountain Trail" style={{ width: '200px', height: '200px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Courses Start --> */}
            <DestinationPage />
            <div className="container-xxl courses my-6 py-6 pb-5" id="destinations">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <Link to='/Book-Now'><video src="img/Travel_post.mp4" autoPlay loop muted playsInline style={{ height: '600px', width: '100%' }}></video></Link>
                    </div>
                </div>
            </div>
            {/* <!-- Courses End --> */}
            <div class="container-xxl py-6">
                <div class="container">
                    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" >
                        <h6 class="text-primary text-uppercase mb-2">Image Gallery</h6>
                        <h1 class="display-6 mb-4">Image Gallery</h1>
                        <p>Discover the by images.</p>
                    </div>
                    <div class="row g-5">
                        <ImageGallery />
                    </div></div></div>
            <TrekkingDestinations />
            <div class="container-xxl py-6">
                <div class="container">
                    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <h6 class="text-primary text-uppercase mb-2">Meet The Team</h6>
                        <h1 class="display-6 mb-4">We Have Great Experience Of Trekking</h1>
                    </div>
                    <div class="row g-0 team-items">
                        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="team-item position-relative">
                                <div class="position-relative">
                                    <img class="img-fluid" src="img/team-1.jpg" alt="" />
                                    <div class="team-social text-center">
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div class="bg-light text-center p-4">
                                    <h5 class="mt-2">Full Name</h5>
                                    <span>Trainer</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="team-item position-relative">
                                <div class="position-relative">
                                    <img class="img-fluid" src="img/team-2.jpg" alt="" />
                                    <div class="team-social text-center">
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div class="bg-light text-center p-4">
                                    <h5 class="mt-2">Full Name</h5>
                                    <span>Trainer</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="team-item position-relative">
                                <div class="position-relative">
                                    <img class="img-fluid" src="img/team-3.jpg" alt="" />
                                    <div class="team-social text-center">
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div class="bg-light text-center p-4">
                                    <h5 class="mt-2">Full Name</h5>
                                    <span>Trainer</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div class="team-item position-relative">
                                <div class="position-relative">
                                    <img class="img-fluid" src="img/team-4.jpg" alt="" />
                                    <div class="team-social text-center">
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square btn-outline-primary border-2 m-1" href=""><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div class="bg-light text-center p-4">
                                    <h5 class="mt-2">Full Name</h5>
                                    <span>Trainer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
            <div className="container-xxl courses my-6 py-6 pb-0">
                <div className="container">
                    <div className="text-center mx-auto mb-3">
                        <h6 className="text-primary text-uppercase mb-2">FIND THE BEST TREKKING TIME</h6>
                        <h1 className="display-6 mb-4">Best Trekking Time</h1>
                        <p>Discover the trekking by choice</p>
                    </div>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item me-3" role="presentation">
                            <button className="nav-link active" id="all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true"><b className='text-dark'>All</b></button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button className="nav-link" id="april-june-tab" data-bs-toggle="pill" data-bs-target="#april-june" type="button" role="tab" aria-controls="april-june" aria-selected="false"><b className='text-dark'>April to June</b></button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button className="nav-link" id="may-june-tab" data-bs-toggle="pill" data-bs-target="#may-june" type="button" role="tab" aria-controls="may-june" aria-selected="false"><b className='text-dark'>May to June</b></button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button className="nav-link" id="sep-oct-tab" data-bs-toggle="pill" data-bs-target="#sep-oct" type="button" role="tab" aria-controls="sep-oct" aria-selected="false"><b className='text-dark'>September to October</b></button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button className="nav-link" id="sep-nov-tab" data-bs-toggle="pill" data-bs-target="#sep-nov" type="button" role="tab" aria-controls="sep-nov" aria-selected="false"><b className='text-dark'>September to November</b></button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div className="row g-4 justify-content-center">
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pindari Glacier Trek</h5>
                                            <p>Located in the Kumaon region of Uttarakhand, this trek takes you through lush forests, quaint villages, and offers stunning views of the Pindari Glacier.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Pindari-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Sunderdhunga Glacier Trek</h5>
                                            <p>Known as the 'Valley of Beautiful Stones,' this trek leads to two glaciers, Maiktoli and Sukhram, offering challenging terrain and panoramic views.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (19).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Sunderdhunga-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kanakata Pass</h5>
                                            <p> This pass is part of a lesser-known trek in Uttarakhand, offering spectacular views of snow-capped peaks and serene landscapes.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (17).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/KanakataPass-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Dhakuri Top</h5>
                                            <p> A popular trekking destination in the Kumaon region, Dhakuri Top provides breathtaking views of the surrounding peaks and valleys.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>2 to 4 days (depending on the itinerary)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (23).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/DhakuriTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pankhu Top</h5>
                                            <p> A relatively lesser-known trek offering scenic beauty, ideal for trekkers seeking solitude and natural beauty.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>3 to 4 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/PankhuTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kafni Glacier Trek</h5>
                                            <p>This trek leads to the Kafni Glacier, nestled in the Kumaon region, offering mesmerizing views of Nanda Kot and Nanda Bhanar peaks.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>7 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (10).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Kafni-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Ranthan Top</h5>
                                            <p>This trek provides a blend of adventure and scenic beauty, with panoramic views of the surrounding mountains</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>3 to 4 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/RanthanTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Trek</h5>
                                            <p>One of the most popular treks in India, Roopkund is famous for its mysterious high-altitude glacial lake, also known as the 'Skeleton Lake.'</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Ronti Saddle Trek</h5>
                                            <p>An extension of the Roopkund trek, this challenging route takes you to the Ronti Saddle, offering even more breathtaking views.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>9 to 11 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Nanda Devi Basecamp Trek</h5>
                                            <p>This trek takes you close to the base of Nanda Devi, the second-highest peak in India, offering stunning vistas of the surrounding Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i> 12 to 14 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (7).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/NandaDeviBasecampTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pangarchulla Trek</h5>
                                            <p>Known for its challenging climb and mesmerizing views, Pangarchulla Peak is a perfect trek for adventure enthusiasts.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i> 7 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery_pangarchulla.jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/PangarchullaTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Brahmatal Trekk</h5>
                                            <p>A winter trek that offers spectacular views of Mount Trishul and Mount Nanda Ghunti, along with frozen lakes and snow-covered landscapes</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>6 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/Brahmatal_trek.jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/BrahmatalTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kuari Pass</h5>
                                            <p>This trek is famous for the "Lord Curzon Trail" and offers stunning views of the Himalayan peaks like Nanda Devi and Dronagiri.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>6 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/KuariPass">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kedarkantha Trek</h5>
                                            <p>A popular winter trek, Kedarkantha offers a beautiful snow trekking experience with views of the mighty Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>6 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/KedarkanthaTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Bagini Glacier Trek</h5>
                                            <p>This trek takes you close to the base of Nanda Devi, the second-highest peak in India, offering stunning vistas of the surrounding Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>12 to 15 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/BaginiGlacierTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* April to June Tab */}
                        <div className="tab-pane fade" id="april-june" role="tabpanel" aria-labelledby="april-june-tab">
                            <div className="row g-4 justify-content-center">
                                {/* Example Trek Item */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div className="text-center p-4 pt-0">
                                            <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 className="mb-3">Pindari Glacier Trek</h5>
                                            <p>Located in the Kumaon region of Uttarakhand, this trek takes you through lush forests, quaint villages, and offers stunning views of the Pindari Glacier.</p>
                                            <ol className="breadcrumb justify-content-center mb-0">
                                                <li className="breadcrumb-item small"><i className="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div className="position-relative mt-auto">
                                            <img className="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div className="courses-overlay">
                                                <Link className="btn btn-outline-primary border-2" to="/Pindari-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Trek</h5>
                                            <p>One of the most popular treks in India, Roopkund is famous for its mysterious high-altitude glacial lake, also known as the 'Skeleton Lake.'</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Dhakuri Top</h5>
                                            <p> A popular trekking destination in the Kumaon region, Dhakuri Top provides breathtaking views of the surrounding peaks and valleys.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>2 to 4 days (depending on the itinerary)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (23).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/DhakuriTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pankhu Top</h5>
                                            <p> A relatively lesser-known trek offering scenic beauty, ideal for trekkers seeking solitude and natural beauty.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>3 to 4 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/PankhuTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Ranthan Top</h5>
                                            <p>This trek provides a blend of adventure and scenic beauty, with panoramic views of the surrounding mountains</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>3 to 4 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/RanthanTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pangarchulla Trek</h5>
                                            <p>Known for its challenging climb and mesmerizing views, Pangarchulla Peak is a perfect trek for adventure enthusiasts.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i> 7 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery_pangarchulla.jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/PangarchullaTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Brahmatal Trekk</h5>
                                            <p>A winter trek that offers spectacular views of Mount Trishul and Mount Nanda Ghunti, along with frozen lakes and snow-covered landscapes</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>6 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/Brahmatal_trek.jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/BrahmatalTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Additional Tabs */}
                        <div className="tab-pane fade" id="may-june" role="tabpanel" aria-labelledby="may-june-tab">
                            <div class="row g-4 justify-content-center">
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Bagini Glacier Trek</h5>
                                            <p>This trek takes you close to the base of Nanda Devi, the second-highest peak in India, offering stunning vistas of the surrounding Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>12 to 15 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/BaginiGlacierTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kafni Glacier Trek</h5>
                                            <p>This trek leads to the Kafni Glacier, nestled in the Kumaon region, offering mesmerizing views of Nanda Kot and Nanda Bhanar peaks.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>7 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (10).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Kafni-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kanakata Pass</h5>
                                            <p> This pass is part of a lesser-known trek in Uttarakhand, offering spectacular views of snow-capped peaks and serene landscapes.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (17).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/KanakataPass-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Nanda Devi Basecamp Trek</h5>
                                            <p>This trek takes you close to the base of Nanda Devi, the second-highest peak in India, offering stunning vistas of the surrounding Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i> 12 to 14 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (7).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/NandaDeviBasecampTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Trek</h5>
                                            <p>One of the most popular treks in India, Roopkund is famous for its mysterious high-altitude glacial lake, also known as the 'Skeleton Lake.'</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Ronti Saddle Trek</h5>
                                            <p>An extension of the Roopkund trek, this challenging route takes you to the Ronti Saddle, offering even more breathtaking views.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>9 to 11 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sep-oct" role="tabpanel" aria-labelledby="sep-oct-tab">
                            <div class="row g-4 justify-content-center">
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Bagini Glacier Trek</h5>
                                            <p>This trek takes you close to the base of Nanda Devi, the second-highest peak in India, offering stunning vistas of the surrounding Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>12 to 15 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/BaginiGlacierTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kafni Glacier Trek</h5>
                                            <p>This trek leads to the Kafni Glacier, nestled in the Kumaon region, offering mesmerizing views of Nanda Kot and Nanda Bhanar peaks.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>7 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (10).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Kafni-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kanakata Pass</h5>
                                            <p> This pass is part of a lesser-known trek in Uttarakhand, offering spectacular views of snow-capped peaks and serene landscapes.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (17).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/KanakataPass-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Nanda Devi Basecamp Trek</h5>
                                            <p>This trek takes you close to the base of Nanda Devi, the second-highest peak in India, offering stunning vistas of the surrounding Himalayas.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i> 12 to 14 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (7).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/NandaDeviBasecampTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Trek</h5>
                                            <p>One of the most popular treks in India, Roopkund is famous for its mysterious high-altitude glacial lake, also known as the 'Skeleton Lake.'</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Roopkund Ronti Saddle Trek</h5>
                                            <p>An extension of the Roopkund trek, this challenging route takes you to the Ronti Saddle, offering even more breathtaking views.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>9 to 11 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Roopkund-Ronti-Saddle-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sep-nov" role="tabpanel" aria-labelledby="sep-nov-tab">
                            <div class="row g-4 justify-content-center">
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pindari Glacier Trek</h5>
                                            <p>Located in the Kumaon region of Uttarakhand, this trek takes you through lush forests, quaint villages, and offers stunning views of the Pindari Glacier.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>8 to 10 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/Pindari-Glacier-Trek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Dhakuri Top</h5>
                                            <p> A popular trekking destination in the Kumaon region, Dhakuri Top provides breathtaking views of the surrounding peaks and valleys.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>2 to 4 days (depending on the itinerary)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (23).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/DhakuriTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pankhu Top</h5>
                                            <p> A relatively lesser-known trek offering scenic beauty, ideal for trekkers seeking solitude and natural beauty.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>3 to 4 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/PankhuTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Ranthan Top</h5>
                                            <p>This trek provides a blend of adventure and scenic beauty, with panoramic views of the surrounding mountains</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>3 to 4 days (depending on the route and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/RanthanTop">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Pangarchulla Trek</h5>
                                            <p>Known for its challenging climb and mesmerizing views, Pangarchulla Peak is a perfect trek for adventure enthusiasts.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i> 7 to 9 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery_pangarchulla.jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/PangarchullaTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Brahmatal Trekk</h5>
                                            <p>A winter trek that offers spectacular views of Mount Trishul and Mount Nanda Ghunti, along with frozen lakes and snow-covered landscapes</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>6 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/Brahmatal_trek.jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/BrahmatalTrek">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div class="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                                            <h5 class="mb-3">Kuari Pass</h5>
                                            <p>This trek is famous for the "Lord Curzon Trail" and offers stunning views of the Himalayan peaks like Nanda Devi and Dronagiri.</p>
                                            <ol class="breadcrumb justify-content-center mb-0">
                                                <li class="breadcrumb-item small"><i class="fa fa-calendar-alt text-primary me-2"></i>6 to 8 days (depending on the itinerary and pace)</li>
                                            </ol>
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="/img/Gallery/places/gallery (11).jpg" width='100%' alt="" />
                                            <div class="courses-overlay">
                                                <Link class="btn btn-outline-primary border-2" to="/KuariPass">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TestimonialCarousel />
        </>
    )
}

export default HomePage