import React from 'react';
import { Link } from 'react-router-dom';

const KafniGlacierTrekDetails = () => {
  return (
    <>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Kafni Glacier Trek</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Destinations</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Kafni Glacier Trek</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-primary text-uppercase mb-2">Kafni Glacier Trek</h6>
              <h1 className="display-6 mb-4">Kafni Glacier Trek</h1>
              <p className="mb-5">The Kafni Glacier Trek is a scenic and less crowded adventure in the Kumaon region of Uttarakhand, India. It offers trekkers a close-up view of the Kafni Glacier, stunning Himalayan vistas, and a rich cultural experience in the remote villages of Kumaon.</p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <span>Kumaon, Uttarakhand, India</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Altitude</h5>
                  </div>
                  <span>Approximately 12,000 feet (3,658 meters)</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Difficulty</h5>
                  </div>
                  <span>Moderate</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 btn-square bg-primary me-3">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <h5 className="mb-0">Best Time to Visit</h5>
                  </div>
                  <span>May to June and September to October</span>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
              <p className="mb-5">The Kafni Glacier Trek is a rewarding journey with the following details:</p>
              <ul className="list-unstyled">
                <li><strong>Base Camp:</strong> Khati Village</li>
                <li><strong>Duration:</strong> 7 to 8 days</li>
                <li><strong>Route:</strong> Loharkhet → Dhakuri → Khati → Dwali → Phurkiya → Kafni Glacier</li>
              </ul>
              <h6 class="text-primary text-uppercase mb-2 mt-5">Itnery</h6>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Day 1
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>kathgodam to khati 250km drive 08-10hrs</strong>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Day 2
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>khati to Dwali 12km 6-8hrs Trek</strong>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Day 3
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Dwali to kafni glacier basecamp 13km 8hrs Trek</strong>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      Day 4
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong> kafni glacier basecamp back to Dwali 13km 7hrs trek</strong>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                      Day 5
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>Dwali to khati 12km trek </strong>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                      Day 6
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>khati to kathgodam</strong>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
              <p className="mb-5">Highlights of the trek include:</p>
              <ul className="list-unstyled">
                <li><strong>Kafni Glacier:</strong> A close-up view of the glacier in the Pindar Valley</li>
                <li><strong>Panoramic Himalayan Views:</strong> Views of peaks like Nanda Kot and Nanda Bhanar</li>
                <li><strong>Natural Beauty:</strong> Diverse landscapes, including forests, meadows, rivers, and waterfalls</li>
                <li><strong>Cultural Experience:</strong> Interaction with remote Kumaoni villages and their traditions</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
              <p className="mb-5">To ensure a smooth trekking experience:</p>
              <ul className="list-unstyled">
                <li><strong>Physical Fitness:</strong> Requires good fitness due to long distances and altitude</li>
                <li><strong>Guides and Porters:</strong> Hiring a local guide and porters is advisable for navigation and support</li>
                <li><strong>Permits:</strong> Required permits can be arranged in Bageshwar</li>
                <li><strong>Packing Essentials:</strong> Warm clothing, sturdy trekking boots, rain gear, a first-aid kit, and high-energy snacks</li>
              </ul>
              <h6 className="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
              <p className="mb-5">Reach the starting point of the trek as follows:</p>
              <ul className="list-unstyled">
                <li><strong>By Air:</strong> Nearest airport is Pantnagar, with a drive to Bageshwar</li>
                <li><strong>By Rail:</strong> Nearest railway station is Kathgodam, about 200 km from Bageshwar</li>
                <li><strong>By Road:</strong> Loharkhet is accessible by road from Bageshwar</li>
              </ul>
              <div className="row mt-3 gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <Link to="/Book-Now"><button className="btn btn-primary py-sm-3 px-sm-5">Book Now</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Kafni Glacier Trek" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="Mountain View" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KafniGlacierTrekDetails;
