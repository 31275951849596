import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const trekkingDestinations = [
    {
        title: "Pindari Glacier Trek",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/Pindari-Glacier-Trek",
       
    },
    {
        title: "Sunderdhunga Glacier",
        imgSrc: "/img/Gallery/places/gallery (19).jpg",
        link: "/Sunderdhunga-Glacier-Trek",
       
    },
    {
        title: "Kanakata Pass",
        imgSrc: "/img/Gallery/places/gallery (17).jpg",
        link: "/KanakataPass-Trek",
       
    },
    {
        title: "Dhakuri Top",
        imgSrc: "/img/Gallery/places/gallery (23).jpg",
        link: "/DhakuriTop",
        
    },
    {
        title: "Pankhu Top",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/PankhuTop",
        
    },
    {
        title: "Kafni Glacier Trek",
        imgSrc: "/img/Gallery/places/gallery (10).jpg",
        link: "/Kafni-Glacier-Trek",
       
    },
    {
        title: "Ranthan Top",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/RanthanTop",
        
    },
    {
        title: "Roopkund Trek",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/Roopkund-Ronti-Saddle-Trek",
        
    },
    {
        title: "Roopkund Ronti Saddle",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/Roopkund-Ronti-Saddle-Trek",
       
    },
    {
        title: "Nanda Devi Basecamp ",
        imgSrc: "/img/Gallery/places/gallery (7).jpg",
        link: "/NandaDeviBasecampTrek",
       
    },
    {
        title: "Pangarchulla Trek",
        imgSrc: "/img/Gallery/places/gallery_pangarchulla.jpg",
        link: "/PangarchullaTrek",
        
    },
    {
        title: "Brahmatal Trek",
        imgSrc: "/img/Gallery/places/Brahmatal_trek.jpg",
        link: "/BrahmatalTrek"
    },
    {
        title: "Kuari Pass",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/KuariPass",
       
    },
    {
        title: "Kedarkantha Trek",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/KedarkanthaTrek",
      
    },
    {
        title: "Bagini Glacier Trek",
        imgSrc: "/img/Gallery/places/gallery (11).jpg",
        link: "/BaginiGlacierTrek",
        
    }
];

const TrekkingDestinations = () => {
  return (
    <div className="container-xxl courses my-6 py-6 pb-0">
      <div className="container">
        <div className="text-center mx-auto mb-3 wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="text-primary text-uppercase mb-2">EXPLORE THE TREKKING DESTINATIONS</h6>
          <h1 className="display-6 mb-4">Trekking Destinations</h1>
          <p>Discover the allure of nature's most breathtaking landscapes, where every trail tells a story.</p>
        </div>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          autoplay
          autoplayTimeout={3000}
          items={4}
          responsive={{
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            1000: {
              items: 4
            }
          }}
        >
          {trekkingDestinations.map((destination, index) => (
            <div key={index} className="item">
              <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">PRICE ON REQUEST</div>
                  <h5 className="mb-3">{destination.title}</h5>
                </div>
                <div className="position-relative mt-auto">
                  <img className="img-fluid" src={destination.imgSrc} width='100%' alt={destination.title} />
                  <div className="courses-overlay">
                    <a className="btn btn-outline-primary border-2" href={destination.link}>Read More</a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default TrekkingDestinations;
