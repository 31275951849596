import './App.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import HomePage from './Components/HomePage';
import About from './Components/About';
import Destination from './Components/Destination';
import Contact from './Components/Contact';
import ImageGallery from './Components/ImageGallery';
import PindariGlacierTrek from './Components/PindariGlacierTrek';
import SunderdhungaGlacierTrek from './Components/SunderdhungaGlacierTrek';
import KanakataPass from './Components/KanakataPass';
import DhakuriTop from './Components/DhakuriTop';
import PankhuTop from './Components/PankhuTop';
import KafniGlacierTrek from './Components/KafniGlacierTrek';
import RanthanTop from './Components/RanthanTop';
import RoopkundTrek from './Components/RoopkundTrek';
import RoopkundRontiSaddleTrek from './Components/RoopkundRontiSaddleTrek';
import NandaDeviBasecampTrek from './Components/NandaDeviBasecampTrek';
import PangarchullaTrek from './Components/PangarchullaTrek';
import BrahmatalTrek from './Components/BrahmatalTrek';
import KuariPass from './Components/KuariPass';
import KedarkanthaTrek from './Components/KedarkanthaTrek';
import BaginiGlacierTrek from './Components/BaginiGlacierTrek';
import PindariGlacierTrekDetails from './Components/PindariGlacierTrekDetails';
import SunderdhungaGlacierTrekDetails from './Components/SunderdhungaGlacierTrekDetails';
import KanakataPassDetails from './Components/KanakataPassDetails';
import DhakuriTopDetails from './Components/DhakuriTopDetails';
import PankhuTopTrekDetails from './Components/PankhuTopTrekDetails';
import KafniGlacierTrekDetails from './Components/KafniGlacierTrekDetails';
import RanthanTopTrekDetails from './Components/RanthanTopTrekDetails ';
import RoopkundTrekDetails from './Components/RoopkundTrekDetails ';
import RoopkundRontiSaddleTrekDetails from './Components/RoopkundRontiSaddleTrekDetails';
import NandaDeviBaseCampTrekDetails from './Components/NandaDeviBaseCampTrekDetails';
import PangarchullaTrekDetails from './Components/PangarchullaTrekDetails';
import BrahmatalTrekDetails from './Components/BrahmatalTrekDetails';
import KuariPassTrekDetails from './Components/KuariPassTrekDetails';
import BaginiGlacierTrekDetails from './Components/BaginiGlacierTrekDetails';
import BookNow from './Components/BookNow';
import KedarkanthaTrekDetails from './Components/KedarkanthaTrekDetails';
import Treks from './Components/Treks';
import TabDetails from './Components/TabDetails';

function App() {
  return (
    <>


      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<HomePage />} />
          <Route path='/about' element={<About />} />
          <Route path='/destination' element={<Destination />} />
          <Route path='/treks' element={<TabDetails />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/image-gallery' element={<ImageGallery />} />
          <Route path='/Pindari-Glacier-Trek' element={<PindariGlacierTrek />} />
          <Route path='/Pindari-Glacier-Trek-Details' element={<PindariGlacierTrekDetails />} />
          <Route path='/Sunderdhunga-Glacier-Trek' element={<SunderdhungaGlacierTrek />} />
          <Route path='/Sunderdhunga-Glacier-Trek-Details' element={<SunderdhungaGlacierTrekDetails />} />
          <Route path='/KanakataPass-Trek' element={<KanakataPass />} />
          <Route path='/KanakataPass-Trek-Details' element={<KanakataPassDetails />} />
          <Route path='/DhakuriTop' element={<DhakuriTop />} />
          <Route path='/DhakuriTop-Details' element={<DhakuriTopDetails />} />
          <Route path='/PankhuTop' element={<PankhuTop />} />
          <Route path='/PankhuTop-Details' element={<PankhuTopTrekDetails />} />
          <Route path='/Kafni-Glacier-Trek' element={<KafniGlacierTrek />} />
          <Route path='/Kafni-Glacier-Trek-Details' element={<KafniGlacierTrekDetails />} />
          <Route path='/RanthanTop' element={<RanthanTop />} />
          <Route path='/RanthanTop-Details' element={<RanthanTopTrekDetails />} />
          <Route path='/Roopkund-Trek' element={<RoopkundTrek />} />
          <Route path='/Roopkund-Details' element={<RoopkundTrekDetails />} />
          <Route path='/Roopkund-Ronti-Saddle-Trek' element={<RoopkundRontiSaddleTrek />} />
          <Route path='/Roopkund-Ronti-Saddle-Trek-Details' element={<RoopkundRontiSaddleTrekDetails />} />
          <Route path='/NandaDeviBasecampTrek' element={<NandaDeviBasecampTrek />} />
          <Route path='/NandaDeviBasecampTrek-Details' element={<NandaDeviBaseCampTrekDetails />} />
          <Route path='/PangarchullaTrek' element={<PangarchullaTrek />} />
          <Route path='/Pangarchulla-Trek-Details' element={<PangarchullaTrekDetails />} />
          <Route path='/BrahmatalTrek' element={<BrahmatalTrek />} />
          <Route path='/Brahmatal-Trek-Details' element={<BrahmatalTrekDetails />} />
          <Route path='/KuariPass' element={<KuariPass />} />
          <Route path='/Kuari-Pass-Details' element={<KuariPassTrekDetails />} />
          <Route path='/KedarkanthaTrek' element={<KedarkanthaTrek />} />
          <Route path='/Kedarkantha-Trek-Details' element={<KedarkanthaTrekDetails />} />
          <Route path='/BaginiGlacierTrek' element={<BaginiGlacierTrek />} />
          <Route path='/Bagini-Glacier-Trek-Details' element={<BaginiGlacierTrekDetails />} />
          <Route path='/Book-Now' element={<BookNow />} />
        </Route>
      </Routes>

    </>
  );
}

export default App;
