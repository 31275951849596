import React from 'react'
import { Link } from 'react-router-dom'
import DestinationPage from './DestinationPage'

const Destination = () => {
    return (
        <>
            <div class="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
                <div class="container text-center">
                    <h1 class="display-4 text-white animated slideInDown mb-4">Popular Trekking Destinations</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb justify-content-center mb-0">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item text-primary active" aria-current="page">Trekking Destinations</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <DestinationPage />
        </>
    )
}

export default Destination