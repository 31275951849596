import React from 'react';
import { Link } from 'react-router-dom';
import TrekkingDestinations from './TrekkingDestinations';

const About = () => {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">About Us</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a className="text-white" href="#">Pages</a>
              </li>
              <li className="breadcrumb-item text-primary active" aria-current="page">About</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- About Start --> */}
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="img/Gallery/gallery (4).jpg" alt="" style={{ objectFit: 'cover' }} />
                <img className="position-absolute top-0 start-0 bg-white pe-3 pb-3" src="img/Gallery/gallery (3).jpg" alt="" style={{ width: '200px', height: '200px' }} />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h6 className="text-primary text-uppercase mb-2">About Us</h6>
                <h1 className="display-6 mb-4">EXPLOREMOZO</h1>
                <p>At EXPLOREMOZO, we believe that every trek is more than just a journey; it’s a chance to reconnect with nature, push your limits, and discover the hidden wonders of the world. Our team of expert guides and outdoor enthusiasts are passionate about creating unforgettable trekking experiences that cater to adventurers of all levels.</p>
                <p className="mb-4">From the serene beauty of remote valleys to the challenging climbs of majestic peaks, we offer a diverse range of trekking routes that showcase the breathtaking landscapes and rich cultures of the regions we explore. Whether you're a seasoned trekker or a first-time hiker, we provide personalized itineraries and professional support to ensure your adventure is safe, enjoyable, and memorable.</p>
                <div className="row g-2 mb-4 pb-2">
                  <div className="col-sm-6">
                    <i className="fa fa-check text-primary me-2"></i>Fully Licensed
                  </div>
                  <div className="col-sm-6">
                    <i className="fa fa-check text-primary me-2"></i>Online Tracking
                  </div>
                  <div className="col-sm-6">
                    <i className="fa fa-check text-primary me-2"></i>Affordable Pricing
                  </div>
                  <div className="col-sm-6">
                    <i className="fa fa-check text-primary me-2"></i>Expert Guides
                  </div>
                </div>
                <div className="row g-4">
                  <div className="col-sm-6">
                    <Link className="btn btn-primary py-3 px-5" to="/destination">Explore More</Link>
                  </div>
                  <div className="col-sm-6">
                    <a className="d-inline-flex align-items-center btn btn-outline-primary border-2 p-2" href="tel:7579060903">
                      <span className="flex-shrink-0 btn-square bg-primary">
                        <i className="fa fa-phone-alt text-white"></i>
                      </span>
                      <span className="px-3">+91 7579060903</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}
               <TrekkingDestinations />
      {/* <!-- Team Start --> */}
      <div className="container-xxl py-6">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <h6 className="text-primary text-uppercase mb-2">Meet The Team</h6>
            <h1 className="display-6 mb-4">Our Experienced Guides</h1>
          </div>
          <div className="row g-0 team-items">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/team-1.jpg" alt="" />
                  <div className="team-social text-center">
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5 className="mt-2">John Doe</h5>
                  <span>Lead Guide</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/team-2.jpg" alt="" />
                  <div className="team-social text-center">
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5 className="mt-2">Jane Smith</h5>
                  <span>Mountain Expert</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/team-3.jpg" alt="" />
                  <div className="team-social text-center">
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5 className="mt-2">Robert Brown</h5>
                  <span>Wilderness Specialist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/team-4.jpg" alt="" />
                  <div className="team-social text-center">
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square btn-outline-primary border-2 m-1" href="#"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h5 className="mt-2">Emily Davis</h5>
                  <span>Trail Guide</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team End --> */}
    </>
  );
};

export default About;
