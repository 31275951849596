import React from 'react'
import { Link } from 'react-router-dom'
const PindariGlacierTrekDetails = () => {
    return (
        <>
            <div class="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
                <div class="container text-center">
                    <h1 class="display-4 text-white animated slideInDown mb-4">Pindari Glacier Trek</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb justify-content-center mb-0">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Destinations</a></li>
                            <li class="breadcrumb-item text-primary active" aria-current="page">Pindari Glacier Trek</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="container-xxl py-6">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h6 class="text-primary text-uppercase mb-2">Pindari Glacier Trek</h6>
                            <h1 class="display-6 mb-4">Pindari Glacier Trek</h1>
                            <p class="mb-5">Discover the Jewel of Kumaon with the Pindari Glacier Trek, a stunning adventure through the Kumaon region of Uttarakhand. This moderate trek features dense forests, charming villages, and awe-inspiring landscapes, culminating at the majestic Pindari Glacier, surrounded by towering peaks.</p>
                            <div class="row gy-5 gx-4">
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Location</h5>
                                    </div>
                                    <span>Kumaon region, Uttarakhand, India</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Difficulty</h5>
                                    </div>
                                    <span>Moderate</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Best Time to Visit</h5>
                                    </div>
                                    <span>April to June and September to November</span>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="flex-shrink-0 btn-square bg-primary me-3">
                                            <i class="fa fa-check text-white"></i>
                                        </div>
                                        <h5 class="mb-0">Duration</h5>
                                    </div>
                                    <span>6-8 days</span>
                                </div>
                            </div>
                            <h6 class="text-primary text-uppercase mb-2 mt-5">Trek Details</h6>
                            <p class="mb-5">The Pindari Glacier Trek offers a blend of scenic beauty and adventure. Here are the key details:</p>
                            <ul class="list-unstyled">
                                <li><strong>Altitude:</strong> 12,300 feet (3,750 meters)</li>
                                <li><strong>Trek Distance:</strong> Approximately 90 km (round trip)</li>
                                <li><strong>Route:</strong> Loharkhet to Dwali, Dwali to Phurkia, and Phurkia to Pindari Glacier</li>
                            </ul>
                            <h6 class="text-primary text-uppercase mb-2 mt-5">Itnery</h6>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Day 1
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Kathgodam to Khati</strong>: 250km drive, approximately 08-10 hours.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Day 2
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Khati to Dwali</strong>: 12km trek, approximately 6-8 hours.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Day 3
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Dwali to Furkiya</strong>: 5km trek, approximately 4 hours.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Day 4
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Furkiya to Pindari Glacier Zero Point</strong>: 19km trek, approximately 8-10 hours. Visit and return to Dwali.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Day 5
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Dwali to Khati</strong>: Return trek.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Day 6
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>Khati to Kathgodam</strong>: Return drive.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h6 class="text-primary text-uppercase mb-2 mt-5">Key Attractions</h6>
                            <p class="mb-5">The trek features several highlights:</p>
                            <ul class="list-unstyled">
                                <li><strong>Pindari Glacier:</strong> The main attraction with breathtaking views</li>
                                <li><strong>Mountain Views:</strong> Panoramas of peaks like Nanda Devi, Nanda Kot, and Panwali Dwar</li>
                                <li><strong>Flora and Fauna:</strong> Diverse ecosystems including dense forests and alpine meadows</li>
                                <li><strong>Cultural Experience:</strong> Interactions with local Kumaoni people and visits to traditional villages</li>
                            </ul>
                            <h6 class="text-primary text-uppercase mb-2 mt-5">Preparation and Tips</h6>
                            <p class="mb-5">To ensure a smooth trekking experience:</p>
                            <ul class="list-unstyled">
                                <li><strong>Fitness:</strong> Good physical fitness is essential due to altitude and long distances</li>
                                <li><strong>Gear:</strong> Bring proper trekking gear including warm clothing and sturdy shoes</li>
                                <li><strong>Permits:</strong> Obtain necessary permits from the forest department</li>
                                <li><strong>Guides and Porters:</strong> Hiring local guides and porters is recommended</li>
                            </ul>
                            <h6 class="text-primary text-uppercase mb-2 mt-5">How to Reach</h6>
                            <p class="mb-5">You can reach the starting point of the trek as follows:</p>
                            <ul class="list-unstyled">
                                <li><strong>By Air:</strong> Nearest airport is Pantnagar, with a drive to Bageshwar</li>
                                <li><strong>By Rail:</strong> Nearest railway station is Kathgodam, about 150 km from Bageshwar</li>
                                <li><strong>By Road:</strong> Bageshwar is well-connected by road to Almora, Nainital, and Kathgodam</li>
                            </ul>
                            <div class="row mt-3 gy-5 gx-4">
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                    <a href="#"><button class="btn btn-primary py-sm-3 px-sm-5">Book Now</button></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="position-relative overflow-hidden pe-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                                <img class="position-absolute w-100 h-100" src="img/TREKKING_PLACE/Pindari-Glacier-Trek.jpg" alt="Pindari Glacier Trek" style={{ objectFit: 'cover' }} />
                                <img class="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/TREKKING_PLACE/Pindari-Glacier-Trek-2.jpg" alt="Mountain View" style={{ width: '200px', height: '200px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PindariGlacierTrekDetails